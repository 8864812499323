<script lang="ts">
	import type { ImageStoryblok } from '$lib/storyblok/types';
	import type { RequiredProduct } from '$lib/types';
	import { getGtmPayloadProductShared } from '$lib/gtm/productDetails';
	import { getBrancheFromCollections } from '$lib/utils';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { getTranslate } from '@tolgee/svelte';
	import { page } from '$app/stores';

	export let title: string;
	export let image: ImageStoryblok;
	export let product: RequiredProduct | undefined = undefined;
	export let showShareIcons: boolean = false;
	$: localShowShareIcons = showShareIcons;

	let copied = false;

	const productGtmPayload = getGtmPayloadProductShared({
		productDetails: {
			availability: product?.availableForSale ? 'in_stock' : 'out_of_stock',
			branche: getBrancheFromCollections(product?.collections),
			price_max: product?.priceRange.maxVariantPrice.amount ?? 0,
			price_min: product?.priceRange.minVariantPrice.amount ?? 0,
			product_category: product?.collections.edges[0]?.node.title ?? '',
			product_id: product?.sku?.value ?? ''
		},
		productSharedTo: 'mail'
	});

	const { t } = getTranslate();

	const startShare = async () => {
		if ('share' in navigator) {
			try {
				await navigator.share({
					text: $t('share.text'),
					title: $t('share.title'),
					url: $page.data.url
				});
				// sendDataToGTM({ ...productGtmPayload, productSharedTo: 'mobile_na' });
			} catch (e) {
				return;
			}
		} else if (!showShareIcons) {
			showShareIcons = true;
		}
	};
</script>

<div class="my-auto">
	<div
		class={classes(
			'border-2 border-accent-primary text-accent-primary transition-all inline-flex flex-shrink-0 cursor-pointer rounded-full items-center justify-center h-12',
			showShareIcons ? 'px-2' : 'w-12'
		)}
	>
		{#if !showShareIcons}
			<button
				on:click={() => startShare()}
				class="flex-shrink-0 w-6 h-6 mx-7 i-sprenger-share"
				aria-hidden="true"
			/>
		{/if}

		{#if showShareIcons}
			<div class="inline-flex items-center justify-center flex-shrink-0 h-12 rounded-full">
				<button
					on:click|stopPropagation={() => {
						navigator.clipboard.writeText($page.url.toString());
						copied = true;
						setTimeout(() => {
							copied = false;
						}, 1000);
						// sendDataToGTM({ ...productGtmPayload, productSharedTo: 'link' });
					}}
					class="flex-shrink-0 w-5 h-5 i-sprenger-link"
					aria-hidden="true"
				/>

				<div
					class="opacity-0 transition-all max-w-0 w-32 duration-1300 flex justify-center line-clamp-1"
					class:opacity-100={copied}
					class:max-w-32={copied}
				>
					{$t('product.copied')}
				</div>
			</div>

			<a
				on:click|stopPropagation={() => {
					// sendDataToGTM({ ...productGtmPayload, productSharedTo: 'fb' });
				}}
				href={`https://www.facebook.com/sharer/sharer.php?u=${$page.url.toString()}`}
				rel="noreferrer"
				target="_blank"
			>
				<div class="i-sprenger-facebook flex-shrink-0 w-5 h-5 ml-1" aria-hidden="true" />
			</a>

			<a
				on:click|stopPropagation={() => {
					// sendDataToGTM({ ...productGtmPayload, productSharedTo: 'pinterest' });
				}}
				href={`https://pinterest.com/pin/create/button/?media=${
					image?.filename
				}&is_video=false&description=${title.replaceAll(' ', '%20')}&url=${$page.url.toString()}`}
				rel="noreferrer"
				target="_blank"
			>
				<div class="i-sprenger-pinterest flex-shrink-0 w-6 h-6 ml-1" aria-hidden="true" />
			</a>

			<a
				on:click|stopPropagation={() => {
					// sendDataToGTM(productGtmPayload);
				}}
				href={`mailto:?subject=${$t('share.title')}&body=${$t(
					'share.text'
				)}%0A%0A${$page.url.toString()}`}
				rel="noreferrer"
				target="_blank"
			>
				<div class="i-sprenger-mail flex-shrink-0 w-6 h-6 ml-1" aria-hidden="true" />
			</a>
		{/if}
	</div>
</div>
